<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.childrenname") }}</label>
                    <inputvselect
                      name="name"
                      :label="(reldata) => reldata.name"
                      :valuex="appData.childrendetailsid"
                      keys="key"
                      @updatedata="
                        (val) => updatedetailschild('childrendetailsid', val)
                      "
                      :options="childrendetails"
                      id="name"
                      validations=""
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="schoolname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.schoolname')
                      "
                      :label="$t('fields.schoolname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.schoolname')
                      "
                      :valuex="appData.schoolname"
                      @updatedata="(val) => (appData.schoolname = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.boardtype") }}</label>
                    <inputselect
                      name="boardtype"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.boardtype')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.boardtype')
                      "
                      :valuex="appData.boardtype"
                      keys="key"
                      @updatedata="(val) => (appData.boardtype = val)"
                      :options="['CBSE', 'ICSE', 'IGCSE', 'IB', 'State']"
                      id="boardtype"
                      validations="required"
                    >
                    </inputselect>
                  </b-col>

                  <!-- <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="name"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.name')"
                      :label="$t('fields.name')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.name')
                      "
                      :valuex="appData.holdername"
                      @updatedata="(val) => (appData.holdername = val)"
                      validations="required"
                    ></inputtext>
                  </b-col> -->

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="gradeclass"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.gradeclass')
                      "
                      :label="$t('fields.gradeclass')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.gradeclass')
                      "
                      :valuex="appData.gradeclass"
                      @updatedata="(val) => (appData.gradeclass = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="yearasfinance"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.yearasfinance')
                      "
                      :label="$t('fields.yearasfinance')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.yearasfinance')
                      "
                      :valuex="appData.yearasfinance"
                      @updatedata="(val) => (appData.yearasfinance = val)"
                      validations=""
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="donation"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.donation')
                      "
                      :label="$t('fields.donation')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.donation')
                      "
                      :valuex="appData.donation"
                      @updatedata="(val) => (appData.donation = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="tutionfees"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.tutionfees')
                      "
                      :label="$t('fields.tutionfees')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.tutionfees')
                      "
                      :valuex="appData.tutionfees"
                      @updatedata="
                        (val) => managefloat('tutionfees', parseFloat(val))
                      "
                      validations="amount"
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <!--  -->
                  <b-col sm="12" md="12" class="mt-1">
                    <div>
                      <b-card no-body>
                        <div class="mt-1">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="btn-icon float-right"
                            v-on:click="addsubject"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                          <h5 class="mt-1">{{ $t("fields.add") }}</h5>
                          <div class="w-100 overflow-scroll py-1">
                            <table class="table" style="width: 145%">
                              <thead>
                                <tr>
                                  <!-- <th>Subject</th> -->
                                  <th width="16%">
                                    {{ $t("fields.examcycle") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.date") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.subject") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.markobtain") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.maximummark") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.rankgrade") }}
                                  </th>
                                  <th width="15%">
                                    {{ $t("fields.remarks") }}
                                  </th>

                                  <th>{{ $t("fields.action") }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in appData.subject"
                                  :key="index + 'addyourown'"
                                >
                                  <th class="">
                                    <b-form-select
                                      name="examcycle"
                                      v-model="appData.subject[index].examcycle"
                                      trim
                                      :key="index + 'examcycle'"
                                      :options="[
                                        'Test',
                                        'Mid-Term',
                                        'Annual',
                                        'Semester',
                                      ]"
                                      :placeholder="
                                        $t('fields.select') +
                                        ' ' +
                                        $t('fields.examcycle')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <flat-pickr
                                      v-model="appData.subject[index].date"
                                      :key="index + 'date'"
                                      :config="{ dateFormat: 'd-m-Y' }"
                                      placeholder="DD-MM-YYYY"
                                      class="form-control"
                                    />
                                  </th>
                                  <th>
                                    <b-form-input
                                      v-model="
                                        appData.subject[index].subjectname
                                      "
                                      trim
                                      :key="index + 'subjectname'"
                                      :placeholder="
                                        $t('fields.enter') +
                                        ' ' +
                                        $t('fields.subject')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <b-form-input
                                      v-model="
                                        appData.subject[index].markobtain
                                      "
                                      trim
                                      :key="index + 'markobtain'"
                                      :placeholder="
                                        $t('fields.enter') +
                                        ' ' +
                                        $t('fields.markobtain')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <b-form-input
                                      v-model="
                                        appData.subject[index].maximummark
                                      "
                                      trim
                                      :key="index + 'maximummark'"
                                      :placeholder="
                                        $t('fields.enter') +
                                        ' ' +
                                        $t('fields.maximummark')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <b-form-input
                                      v-model="appData.subject[index].rankgrade"
                                      trim
                                      :key="index + 'rankgrade'"
                                      :placeholder="
                                        $t('fields.enter') +
                                        ' ' +
                                        $t('fields.rankgrade')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <b-form-input
                                      v-model="appData.subject[index].remarks"
                                      trim
                                      :key="index + 'remarks'"
                                      :placeholder="
                                        $t('fields.enter') +
                                        ' ' +
                                        $t('fields.remarks')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      class="mr-1"
                                      variant="outline-danger"
                                      v-on:click="removesubject(index)"
                                    >
                                      <feather-icon icon="TrashIcon" />
                                    </b-button>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </b-card>
                    </div>
                  </b-col>
                  <hr />

                  <!-- //////////////////////////// -->
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File"
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle">
                          {{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    BFormSelect,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "children-school";
    const modulename = "Schooling";
    const modulenamesub = "school";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      holdername: "",
      gradeclass: "",
      schoolname: "",
      yearasfinance: "",
      boardtype: "",
      donation: "",
      childrensname: "",
      tutionfees: 0,
      subject: [],

      childrendetailsid: "",
      remarks: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      let appdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,

        holdername: temp.holdername,
        name: temp.boardtype,
        gradeclass: temp.gradeclass,
        boardtype: temp.boardtype,
        yearasfinance: temp.yearasfinance,
        subject: temp.subject || [],
        tutionfees: temp.tutionfees,
        childrendetailsid: temp.childrendetailsid,
        schoolname: temp.schoolname,
        childrensname: temp.childrensname,
        donation: temp.donation,
        remarks: temp.remarks,
      };
      let metadataappdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,
        holdername: temp.holdername,
        name: temp.boardtype,
        subject: temp.subject || [],
        childrendetailsid: temp.childrendetailsid,
      };

      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.boardtype,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.boardtype);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    const childrendetails = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchechildrendetails")
      .then((response) => {
        childrendetails.value = response.data.data;
      });
    // const state = ref([]);
    // store
    //   .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
    //   .then((response) => {
    //     state.value = response.data.data;
    //   });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const propertytype = [
      "Primary Residence ",
      "Investment",
      "Vacation Home",
      "Rented",
      "Others",
    ];
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      propertytype,
      key,
      familymembers,
      tempcatch,
      childrendetails,
      // state
    };
  },
  mounted() {
    if(this.appData.subject.length ==0){
        this.addsubject()
    }
  },
  methods: {
    addsubject() {
      var template = {
        maximummark: "",
        date: "",
        examcycle: "",
        subjectname: "",
        examcycle: "",
        markobtain: "",
        rankgrade: "",
        remarks: "",
      };
      this.appData.subject.push(template);
    },
    removesubject(index) {
      if (confirm("are you sure you want to remove")) {
        this.appData.subject.splice(index, 1);
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updatedetailschild(tit, val) {
      if (tit == "childrendetailsid") {
        this.appData.childrendetailsid = val.id;
        this.appData.childrensname = val.name;
      }
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File value Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
